<!--
 * @Author: pengyu
 * @Date: 2021-07-05 09:31:16
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 11:51:27
 * @Description: file content
-->
<template>
  <div class="payment-header">
    <img v-show="orderInfo.showTenantLogo" :src="channelLogo" alt="" class="logo">
    <div class="divider"></div>
    <span class="title">支付中心</span>
  </div>
</template>

<script>
export default {
  name: "PaymentHeader",
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    channelLogo() {
      const channelLogo = this.orderInfo.tenantLogo?.channelLogo;
      // eslint-disable-next-line global-require
      return channelLogo || require("@/assets/images/logoall.png");
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.payment-header {
  height: 60px;
  border-bottom: 1px solid #e8ecf2;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  .logo {
    width: 94px;
    height: 30px;
    margin-left: 20px;
    margin-right: 15px;
  }
  .divider {
    width: 1px;
    height: 30px;
    background: #e8ecf2;
    margin-right: 18px;
  }
  .title {
    font-size: 14px;
    color: #333333;
    font-weight: bold;
  }
}
</style>
