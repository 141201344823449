<!--
 * @Author: pengyu
 * @Date: 2021-07-05 09:32:15
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 09:32:52
 * @Description: file content
-->
<template>
  <div class="payment-union" v-loading="loading">
    <el-form label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="union-ruleForm">
      <el-form-item label="账户类型" prop="bankAccountType">
        <el-select v-model="ruleForm.bankAccountType" placeholder="请选择账户类型">
          <el-option v-for="item in accountOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行卡类型" prop="bankCardType" v-if="ruleForm.bankAccountType === '11'">
        <el-select v-model="ruleForm.bankCardType" placeholder="请选择银行卡类型">
          <el-option v-for="item in bankTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户银行" prop="bankId">
        <el-select v-model="ruleForm.bankId" filterable placeholder="请选择开户银行">
          <el-option v-for="item in bankOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <span class="pay-btn" @click="submitForm('ruleForm')">支付</span>
    </el-form>
  </div>
</template>

<script>
import { personalBankList, companyBankList } from "@/assets/json/bank";

// eslint-disable-next-line max-len
export default {
  name: "PaymentUnion",
  data() {
    return {
      accountOptions: [
        {
          label: "个人账户",
          value: "11"
        },
        {
          label: "企业账户",
          value: "12"
        }
      ],
      bankTypeOptions: [
        {
          label: "储蓄卡",
          value: "10"
        }
        // {
        //   label: "贷记账户",
        //   value: "20"
        // }
      ],
      ruleForm: {
        bankAccountType: "11",
        bankCardType: "10",
        bankId: ""
      },
      rules: {
        bankAccountType: [
          { required: true, message: "请选择账户类型", trigger: "change" }
        ],
        bankCardType: [
          { required: true, message: "请选择银行卡类型", trigger: "change" }
        ],
        bankId: [
          { required: true, message: "请选择开户银行", trigger: "change" }
        ]
      },
      loading: false
    };
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    "ruleForm.bankAccountType"() {
      this.ruleForm.bankId = "";
    }
  },
  computed: {
    bankOptions() {
      if (this.ruleForm.bankAccountType === "11") {
        return personalBankList;
      }
      return companyBankList;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("confirmPay", this.ruleForm);
          this.loading = true;
        } else {
          console.log("error submit!!");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-union {
  width: 880px;
  height: 380px;
  background: #f5f7fa;
  margin: 0 auto 90px auto;
  .union-ruleForm {
    width: 680px;
    box-sizing: border-box;
    padding-top: 64px;
    margin: 0 auto 90px auto;
    .el-select {
      width: 100%;
    }
    .pay-btn {
      width: 360px;
      height: 32px;
      margin: 60px auto 0 auto;
      line-height: 32px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      border-radius: 4px;
      background: #179eda;
      cursor: pointer;
      display: block;
    }
  }
}
</style>
