<!--
 * @Author: pengyu
 * @Date: 2021-07-05 09:27:50
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 13:55:42
 * @Description: 中金支付链接
-->
<template>
  <div class="payment">
    <payment-header :order-info="orderInfo"></payment-header>
    <div class="payment-content">
      <payment-info :content-list="orderInfo.content"></payment-info>
      <payment-mode :order-info="orderInfo" v-if="paySuccessFlag === 0"></payment-mode>
      <div class="success" v-else>
        <div>
          <img src="../../assets/images/success.png" alt="" />
          <p class="success-text">支付成功</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { paymentServeice } from "@/api/payment";
import PaymentHeader from "./components/PaymentHeader";
import PaymentInfo from "./components/PaymentInfo";
import PaymentMode from "./components/PaymentMode";

export default {
  name: "Payment",
  components: {
    PaymentHeader,
    PaymentInfo,
    PaymentMode
  },
  data() {
    return {
      paySuccessFlag: 0,
      // 订单是否存在
      existOrder: true,
      orderInfo: {
        orderId: "",
        content: [],
        weChatPayFlag: 0,
        aliPayFlag: 0,
        unionPayFlag: 0,
        showTenantLogo: 0,
        tenantLogo: {
          channelLogo: "",
          channelIconLogo: "http://mail.talkingchina.com/static/0.1.6/images/favicon.ico"
        }
      }
    };
  },
  watch: {
    paySuccessFlag(val) {
      function getElementToPageTop(el) {
        if (el.parentElement) {
          return getElementToPageTop(el.parentElement) + el.offsetTop;
        }
        return el.offsetTop;
      }
      if (val) {
        setTimeout(() => {
          const successDom = document.querySelector(".success");
          console.log(getElementToPageTop(successDom));
          successDom.style.height = `${document.documentElement.clientHeight - getElementToPageTop(successDom) - 10}px`;
        }, 300);
      }
    }
  },
  computed: {
    orderId() {
      return this.$route.query.orderId;
    }
  },
  methods: {
    // 获取支付信息
    async queryZjPayOrderInfo() {
      try {
        const res = await paymentServeice.queryZjPayOrderInfo({
          orderId: this.orderId
        });
        Object.assign(this.orderInfo, res);
        console.log(res);
        if (res.showTenantLogo === 1) {
          document.querySelector("link").href = res.tenantLogo.channelIconLogo;
        }
      } catch (error) {
        this.existOrder = false;
      }
    },
    // 获取支付状态
    async getPayStatus() {
      const res = await paymentServeice.queryZjPayOrderPayStatus({
        orderId: this.orderId
      });
      this.paySuccessFlag = res.paySuccessFlag;
      if (this.paySuccessFlag === 0 && this.existOrder) {
        setTimeout(() => {
          this.getPayStatus();
        }, 5000);
      }
    }
  },
  mounted() {
    this.orderInfo.orderId = this.orderId;
    this.getPayStatus();
    this.queryZjPayOrderInfo();
  }
};
</script>

<style lang="scss" scoped>
.payment {
  overflow-y: scroll;
  .payment-content {
    padding: 10px;
    .success {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #e8ecf2;
      text-align: center;
      align-items: center;
      min-height: 200px;
      img {
        margin-bottom: 16px;
        width: 112px;
      }
      .success-text {
        font-size: 12px;
        color: #91a1b7;
        text-indent: 12px;
      }
    }
  }
}
</style>
