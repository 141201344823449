var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-info" },
    _vm._l(_vm.contentList, function (item, index) {
      return _c("div", { key: index, staticClass: "info-item" }, [
        _c("span", [_vm._v(_vm._s(item.name) + " ：")]),
        item.attachList && item.attachList.length
          ? _c(
              "p",
              { attrs: { title: item.value } },
              _vm._l(item.attachList, function (attach, idx) {
                return _c(
                  "a",
                  {
                    key: idx,
                    staticClass: "download-link",
                    attrs: {
                      title: "点击下载",
                      target: "_blank",
                      href: attach.url,
                    },
                  },
                  [_vm._v(_vm._s(attach.name))]
                )
              }),
              0
            )
          : _c(
              "p",
              {
                class: { amount: item.fieldType === 4 },
                attrs: { title: item.value },
              },
              [
                _vm._v(" " + _vm._s(item.value) + " "),
                item.fieldType === 4
                  ? _c("span", { staticStyle: { color: "#333" } }, [
                      _vm._v(" 元"),
                    ])
                  : _vm._e(),
              ]
            ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }