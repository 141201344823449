var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment" },
    [
      _c("payment-header", { attrs: { "order-info": _vm.orderInfo } }),
      _c(
        "div",
        { staticClass: "payment-content" },
        [
          _c("payment-info", {
            attrs: { "content-list": _vm.orderInfo.content },
          }),
          _vm.paySuccessFlag === 0
            ? _c("payment-mode", { attrs: { "order-info": _vm.orderInfo } })
            : _c("div", { staticClass: "success" }, [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/success.png"),
                      alt: "",
                    },
                  }),
                  _c("p", { staticClass: "success-text" }, [
                    _vm._v("支付成功"),
                  ]),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }