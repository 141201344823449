<!--
 * @Author: pengyu
 * @Date: 2021-07-05 09:31:44
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 13:40:59
 * @Description: file content
-->
<template>
  <div class="payment-info">
    <div class="info-item" v-for="(item, index) in contentList" :key="index">
      <span>{{item.name}} ：</span>
      <p v-if="item.attachList && item.attachList.length" :title="item.value">
        <a class="download-link" v-for="(attach, idx) in item.attachList" title="点击下载" :key="idx" target="_blank" :href="attach.url">{{attach.name}}</a>
      </p>
      <p :class="{amount: item.fieldType === 4}" :title="item.value" v-else>
        {{item.value}}
        <span v-if="item.fieldType === 4" style="color: #333;"> 元</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentInfo",
  props: {
    contentList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.payment-info {
  background: #f5f7fa;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  padding: 26px 0 20px 0;
  margin: 10px 0;
  .info-item {
    width: 50%;
    display: flex;
    font-size: 12px;
    color: #333;
    padding-left: 40px;
    margin-bottom: 22px;
    p {
      margin: 0;
      flex: 1;
      line-height: 16px;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal!important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
  }
  .download-link {
    color: #179eda;
    cursor: pointer;
    margin-right: 10px;
    text-decoration: none;
  }
  .amount {
    color: #f78528;
  }
}
</style>
