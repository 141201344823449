var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-header" }, [
    _c("img", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.orderInfo.showTenantLogo,
          expression: "orderInfo.showTenantLogo",
        },
      ],
      staticClass: "logo",
      attrs: { src: _vm.channelLogo, alt: "" },
    }),
    _c("div", { staticClass: "divider" }),
    _c("span", { staticClass: "title" }, [_vm._v("支付中心")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }